import * as React from 'react'
import Layout from '../components/Layout/Layout'
import './flex.scss'
import './index.scss'
import { ImParagraphRight, ImNewspaper, ImStatsBars } from 'react-icons/im'
import { GiSpeedometer } from 'react-icons/gi'
import { VscSmiley } from 'react-icons/vsc'
import { SiGoogletagmanager } from 'react-icons/si'
import { BsCheckCircle } from 'react-icons/bs'
import { CgCloseO } from 'react-icons/cg'
import FeatureCard from '../components/FeatureCard/FeatureCard'

import Google from '../images/brands/google.jpg'
import Amazon from '../images/brands/amazon.jpg'
import Spotify from '../images/brands/spotify.jpg'
import Apple from '../images/brands/apple.jpg'
import Podcast from '../images/podcast.svg'
import Summarize from '../images/summary.svg'
import Analyze from '../images/analyze.svg'
import Banner from '../images/banner.svg'
import Hero from '../images/hero.svg'
import SEO from '../components/seo'

// markup
const IndexPage = () => {
  const features = [
    {
      bg: 'linear-gradient(135deg, #c56cd6 0%, #3425af 100%)',
      icon: <ImParagraphRight size={30} color="#fff" />,
      heading: 'Summarization',
      body: 'Receive a summary which extracts all the key moments from your podcast in a concise and easy-to-read format.',
    },
    {
      bg: 'linear-gradient(135deg, #F36265 0%, #961276 100%)',
      icon: <GiSpeedometer size={30} color="#fff" />,
      heading: 'Pace',
      body: 'Have you ever wondered if you were speaking too quickly or slowly? Podcise can give you details on your pace so you can better manage it.',
    },
    {
      bg: 'linear-gradient(135deg, #184e68 0%, #57ca85 100%)',
      icon: <VscSmiley size={30} color="#fff" />,
      heading: 'Sentiment',
      body: 'Sentiment analysis allows you to check if the mood you wanted to get across is being conveyed to your audience.',
    },
    {
      bg: 'linear-gradient(135deg, #7117ea 0%, #c53364 100%)',
      icon: <ImNewspaper size={30} color="#fff" />,
      heading: 'Transcription',
      body: 'You can obtain a complete, speaker-separated transcription of your podcast, annotated with all the insights we provide.',
    },
    {
      bg: 'linear-gradient(135deg, #17ead9 0%, #6078ea 100%)',
      icon: <ImStatsBars size={30} color="#fff" />,
      heading: 'Pitch',
      body: 'Any moments of excitement, passion, or nervousness in your podcast? Easily identify them using our pitch analysis system!.',
    },
    {
      bg: 'linear-gradient(135deg, #fcdf8a 0%, #f38381 100%)',
      icon: <SiGoogletagmanager size={30} color="#fff" />,
      heading: 'Management',
      body: 'Podcise offers a platform for you to easily look over the insights, and create a document containing key information.',
    },
  ]
  return (
    <Layout>
      <SEO title="Podcise Home" />
      <div className="hero">
        <div className="row container-fluid">
          <div className="hero__image col-md-6">
            <img src={Hero} alt="hero" />
          </div>

          <div className="hero__text col-md-6">
            <div className="hero__text__container">
              <h1>Automatic podcast transcription and summary in minutes</h1>
              <h4>
                Podcise automatically transcribes your audio in minutes,
                summarizes the content and identifies the most interesting parts
                of the conversation.
              </h4>
              <a
                href="https://app.podcise.com"
                className="btn btn__primary mt-20"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="features container mt-80">
        <h2 className="center mb-20">Features</h2>
        <div className="row around-xs">
          {features.map((feature, i) => {
            const { icon, body, heading, bg } = feature
            return (
              <div className="col-sm-6 col-md-4 " key={i}>
                {' '}
                <FeatureCard
                  icon={icon}
                  heading={heading}
                  body={body}
                  bg={bg}
                />
              </div>
            )
          })}
        </div>
      </div>

      <div className="brand mt-80 container">
        <h2 className="center mb-50">Trusted on large and small podcasts</h2>
        <div className="row">
          <div className="brand__image  col-md-3">
            <img src={Google} alt="google" />
          </div>

          <div className="brand__image  col-md-3">
            <img src={Amazon} alt="google" />
          </div>
          <div className="brand__image  col-md-3">
            <img src={Apple} alt="google" />
          </div>

          <div className="brand__image  col-md-3">
            <img src={Spotify} alt="google" />
          </div>
        </div>
      </div>
      <div className="steps container mt-80">
        <h2 className="center mb-50">Easy process</h2>
        <div className="row">
          <div className="col-md-4">
            <div className="w-75 card2 center">
              <img src={Podcast} className="center" alt="transcribe" />
              <h2 className="center">Transcribe</h2>
              <p>
                With a few clicks take recordings and turn them into clear
                documents
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="w-75 card2 center">
              <img src={Summarize} className="center" alt="summarize" />

              <h2 className="center">Summarize</h2>
              <p>
                Quickly identify the most important parts of an audio file to
                eliminate typing
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="w-75 card2 center">
              <img src={Analyze} className="center" alt="analyze" />
              <h2>Analyze </h2>
              <p>
                Identify the most important points of the recording saving hours
                of research
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="banner mt-80">
        <div className="row">
          <div className="col-md-6  align-center d-flex">
            <div className="center">
              <h2>Podcise makes it simple</h2>
              <h3>Discover how Podcise saves you hours of work. </h3>
              <a
                href="https://app.podcise/com"
                className="mt-20 btn btn__primary"
              >
                Get Started
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="banner__image center">
              <img src={Banner} alt="banner" />
            </div>
          </div>
        </div>
      </div>
      <div className="pricing mt-80 ">
        <h2 className="text-grey center mb-40">
          Plans According to your needs
        </h2>
        <div className="row container">
          <div className="col-md-6 center d-flex justify-center">
            <div className="card3 w-75 round">
              {' '}
              <h2>Free Plan</h2>
              <ul className="mt-20 left">
                <li>
                  <BsCheckCircle size={20} color="green" /> &nbsp; 30 minutes
                  per month of analysis
                </li>
                <li>
                  <BsCheckCircle size={20} color="green" /> &nbsp; All Insights
                  Provided
                </li>
                <li>
                  <CgCloseO size={20} color="red" /> &nbsp; Download Insights
                </li>
                <li>
                  <CgCloseO size={20} color="red" /> &nbsp; Early Access to Beta
                  Testing
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 center d-flex justify-center">
            <div className="card4 w-75 round ">
              {' '}
              <h2>Premium Plan</h2>
              <ul className="mt-20 left">
                <li>
                  <BsCheckCircle size={20} color="green" /> &nbsp; Upto 20 hours
                  per month of analysis
                </li>
                <li>
                  <BsCheckCircle size={20} color="green" /> &nbsp; All Insights
                  Provided
                </li>
                <li>
                  <BsCheckCircle size={20} color="green" /> &nbsp; Download
                  Insights
                </li>
                <li>
                  <BsCheckCircle size={20} color="green" /> &nbsp; Early Access
                  to Beta Testing
                </li>
              </ul>
            </div>
          </div>
          <div className="center mt-50">
            <a
              href="https://app.podcise.com"
              className="btn btn__primary mt-20"
            >
              Get Started
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
