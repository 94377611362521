import React from 'react'
import './featurecard.scss'

function FeatureCard({ icon, body, heading, bg }) {
  return (
    <div className="card features__card">
      <div
        className="features__card__icon"
        style={{
          background: bg,
        }}
      >
        {icon}
      </div>
      <h3 className="mt-10">{heading}</h3>
      <p className="mt-10">{body}</p>
    </div>
  )
}

export default FeatureCard
